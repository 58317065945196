// RTL languages directions.
$left: right;
$right: left;

$ltr: rtl;
$rtl: ltr;

$font: "Rubik", sans-serif;
$font2: "Heebo", sans-serif;
$font3: "Heebo", sans-serif;
